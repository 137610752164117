<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useAuthStore } from "@/store/auth";

const { $tt } = useNuxtApp();
const store = useAppStore();
const auth = useAuthStore();
const interest = 0.25;
const period = [{ months: 18 }, { months: 32 }, { months: 48 }];
</script>

<template>
  <div class="container">
    <h3 class="font-poppins text-[32px] mt-4 lg:mb-11">
      {{ $tt("landing.calculator.title") }}
    </h3>

    <div
      class="flex justify-between flex-col gap-y-10 md:flex-row md:gap-y-0 md:gap-x-10 mt-4"
    >
      <div class="flex-initial w-full lg:w-[330px] self-center">
        <p class="font-poppins text-xl font-normal mb-4 w-full">
          {{ $tt("landing.calculator.content") }}
        </p>

        <template v-if="!auth.isAuthenticated">
          <button
            class="rounded-lg py-[10px] px-5 text-black bg-primary font-medium hover:bg-primary-500"
            @click="() => store.setOpenSidebarRegister(true)"
          >
            {{ $tt("shared.beInvestor") }}
          </button>
        </template>
        <template v-else>
          <NuxtLink
            to="/oportunidades"
            class="rounded-lg py-[10px] px-5 text-black bg-primary font-medium mt-2 hover:bg-primary-500"
          >
            {{ $tt("landing.calculator.seeOpportunities") }}
          </NuxtLink>
        </template>
      </div>

      <div class="flex w-full justify-center px-4 lg:w-8/12 lg:max-w-[850px]">
        <AppCalculatorBase :period="period" :interest="interest" />
      </div>
    </div>
  </div>
</template>
